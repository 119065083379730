import React from "react";
import css from "./WhoWhen.module.css";

const WhoWhen = ({ alt, date, id, name, src }) => {
  return (
    <div className={css.main} id={id}>
      <div className={css.container}>
        {src && alt && <img className={css.img} src={src} alt={alt} />}
        <p>{name}</p>
      </div>
      <p>{date}</p>
    </div>
  );
};

export default WhoWhen;
