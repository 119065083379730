import React from "react";
import css from "./Resource.module.css";
import Bullet from "../Bullet/Bullet";
import check from "../../images/check.png";

const Resource = ({
  alt,
  background,
  bullets,
  children,
  description,
  src,
  title,
}) => {
  return (
    <div className={`${css.main} ${background ? "background" : "white"}`}>
      <h3 className={css.title}>{title}</h3>
      <p className={css.description}>{description}</p>
      <img className={css.image} src={src} alt={alt} />
      <ul>
        {bullets?.map((bullet, index) => (
          <Bullet key={index} alt="check" src={check} text={bullet} />
        ))}
      </ul>
      <div className={css.children}>{children}</div>
    </div>
  );
};

export default Resource;
