import React from "react";
import Intro from "../sections/Intro";
import Header from "../components/Header/Header";
import Included from "../components/Included/Included";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Benefit from "../components/Benefit-2/Benefit";
import L2R1 from "../components/L2R1/L2R1";

import audit from "../images/audit.png";
import balanceSheet from "../images/balanceSheet.png";
import cashFlow from "../images/cashFlow.png";
import customReport from "../images/customReport.png";
import dataDriven from "../images/dataDriven.png";
import funding from "../images/funding.png";
import maximize from "../images/maximize.png";
import pandl from "../images/pandl.png";
import risk from "../images/risk.png";

const Consulting = () => {
  return (
    <div className="page">
      <Header />
      <Intro
        primary="We'll look to the future."
        secondary="Understand the ins and outs of your finances so that you can make the most informed business decisions."
        image={1}
      />
      <div className="section background">
        <h2>See what's included.</h2>
        <Included
          title="Balance Sheet Forecast"
          bullets={["Predict Future Equity", "Plan Proactively", "Manage Risk"]}
        />
        <Included
          title="Custom Reporting"
          bullets={[
            "Tailored Financial Insights",
            "Individualized Benchmarking",
            "Identify Opportunities",
          ]}
        />
        <Included
          title="Cash Flow Forecasting"
          bullets={[
            "Predict Future Equity",
            "Manage Cash Effectively",
            "Identify Potential Shortages",
          ]}
        />
        <Included
          title="Profit and Loss Forecasting"
          bullets={["Manage Costs"]}
        />
        <Link className="link" to="/contact">
          Get Started
        </Link>
      </div>
      <div className="section">
        <h2>Check out the many benefits.</h2>
        <div className="benefits-2">
          <Benefit
            title="Set Attainable Goals"
            src={maximize}
            alt="bar chart showing upward growth"
          />
          <Benefit
            title="Predict Profitability"
            src={audit}
            alt="magnifying glass spying on a checkmark"
          />
          <Benefit
            title="Get More Funding"
            src={funding}
            alt="a bag with a dollar symbol on it"
          />
          <Benefit title="Manage Risk" src={risk} alt="warning sign." />
          <Benefit
            title="Data-Driven Decisions"
            src={dataDriven}
            alt="bar chart with an upwards arrow instead of a middle bar."
          />
        </div>
        <Link className="link" to="/contact">
          Get Started
        </Link>
      </div>
      <div className="section background">
        <h2 style={{ color: "#886aff" }}>Just look at those prices.</h2>
        <div className="pricing">
          <L2R1
            left="Custom Report"
            right="$100"
						src={customReport}
            alt="a paper with a bar graph and donut chart on it"
          />
          <L2R1
            left="Cash Flow Forecast"
            right="$250"
            src={cashFlow}
            alt="a dollar sign with a rotating arrow around it."
          />
          <L2R1
            left="Profit & Loss Forecast"
            right="$250"
            src={pandl}
            alt="a dollar sign with a downward-facing arrow to its left and an upward-facing arrow on its right."
          />
          <L2R1
            left="Balance Sheet Forecast"
            right="$250"
            src={balanceSheet}
            alt="balancing scale icon"
          />
        </div>
        <Link className="link" to="/contact">
          Get Started
        </Link>
      </div>
      <Footer background />
    </div>
  );
};

export default Consulting;
