import React, { useState } from "react";

import Input from "../Input/Input";

const DownloadForm = ({ asset, sheets }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const updateEmail = (e) => {
    setEmailError("");
    setEmail(e.target.value);
  };

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const isValid = () => {
    if (!email || typeof email !== "string") {
      setEmailError("Enter a valid email.");
      return false;
    }
    return true;
  };

  function downloadFile() {
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = asset.href;
    link.download = asset.download;

    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      URL.revokeObjectURL(link.href);
      link.parentNode.removeChild(link);
    }, 0);
  }

  const submit = async () => {
    setLoading(true);
    if (isValid()) {
      try {
        let response = await fetch(
          "https://backend-dot-mantle-xm.wm.r.appspot.com/api/crystal-clear-consulting/download",
          {
            method: "POST",
            body: JSON.stringify({ email, download: asset.download }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          downloadFile();
          setSubmitted(true);
          setEmail("");
        } else {
          throw new Error("Something went wrong.");
        }
      } catch (error) {
        setEmailError("Something went wrong. Please try again.");
        console.error(error);
      }
    }
    setLoading(false);
  };

  return (
    <form>
      <Input
        style={{ textAlign: "center" }}
        error={emailError}
        label="Enter your email."
        onChange={updateEmail}
        type="email"
        value={email}
        id="email"
      />
      {submitted ? (
        <div className="submitted">Thanks! Enjoy!</div>
      ) : (
        [
          <button
            key={0}
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              submit(asset.download);
            }}
          >
            {loading ? "Loading..." : asset.text}
          </button>,
          sheets && (
            <a
              key={1}
              style={{ textAlign: "center" }}
              href="https://docs.google.com/spreadsheets/d/1bKZLREVNX3wLr1QKKRfvTmpNpiqibF-VJGkwZqsaa78/template/preview"
              rel="noopener noreferrer"
              target="_blank"
            >
              Access on Google Sheets
            </a>
          ),
        ]
      )}
    </form>
  );
};

export default DownloadForm;
