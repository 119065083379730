import React from "react";
import css from "./Paragraph.module.css";

const Paragraph = ({ children, type }) => {
  let style = {};

  switch (type) {
    case "info":
      style.padding = "2rem";
      style.background = "#f0ecff";
      // style.maxWidth = "60rem";
      break;
    default:
      break;
  }
  return (
    <p style={style} className={css.main}>
      {children}
    </p>
  );
};

export default Paragraph;
