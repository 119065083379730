import React from "react";
import css from "./Benefit.module.css";

const Benefit = ({ alt, src, title }) => {
  return (
    <div className={css.main}>
      <img className={css.image} src={src} alt={alt} />
      <span className={css.title}>{title}</span>
    </div>
  );
};

export default Benefit;
