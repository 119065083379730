import React from "react";
import { Link } from "react-router-dom";
import css from "./Service.module.css";

const Service = ({ alt, description, link, src, title }) => {
  return (
    <div className={`${css.main} background service`}>
      <img src={src} alt={alt} />
      <h3>{title}</h3>
      <p>{description}</p>
      <Link className="link" to={link}>
        Learn More
      </Link>
    </div>
  );
};

export default Service;
