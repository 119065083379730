import React from "react";
import css from "./Input.module.css";

const Input = ({
  autoFocus,
  error,
  id,
  label,
  onChange,
  placeholder,
  style,
  type,
  value,
}) => {
  return (
    <div className={css.main} style={style}>
      {label && (
        <label
          htmlFor={id}
          className={error ? `${css.error} ${css.label}` : `${css.label}`}
        >
          {error || label}
        </label>
      )}
      <input
        style={style}
        autoFocus={autoFocus}
        className={css.input}
        id={id}
        placeholder={placeholder}
        value={value}
        type={type || "text"}
        onChange={onChange}
      />
    </div>
  );
};

export default Input;
