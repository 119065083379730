import React from "react";
import css from "./Preview.module.css";
import { Link } from "react-router-dom";

const Preview = ({ alt, date, description, link, src, title }) => {
  return (
    <Link to={link} className={css.main}>
      {src && alt && <img src={src} alt={alt} className={css.img} />}
      <h3 className={css.title}>{title}</h3>
      <p className={css.description}>{description}</p>
      <p className={css.info}>{date}</p>
    </Link>
  );
};

export default Preview;
