import React from "react";
import Header from "../components/Header/Header";
import Included from "../components/Included/Included";
import Benefit from "../components/Benefit-2/Benefit";
import L2R1 from "../components/L2R1/L2R1";
import { Link } from "react-router-dom";
import Footer from "../components/Footer/Footer";

import audit from "../images/audit.png";
import check from "../images/check.png";
import guidance from "../images/guidance.png";
import large from "../images/large.png";
import logo from "../images/logo.png";
import maximize from "../images/maximize.png";
import medium from "../images/medium.png";
import perfect from "../images/perfect.png";
import small from "../images/small.png";
import time from "../images/time.png";

import FinanceTracker from "../components/Resource/FinanceTracker";
import Intro from "../sections/Intro";

import Bullet from "../components/Bullet/Bullet";

const Bookkeeping = () => {
  return (
    <div className="page">
      <Header />
      <Intro
        primary="We'll look after your books."
        secondary="Delegate day-to-day bookkeeping tasks, giving yourself more time to do
          what you love."
        image={2}
      />
      <div className="section background">
        <h2>See what's included.</h2>
        <div className="disclaimer white">
          <Bullet
            alt="crystal clear consulting logo"
            src={logo}
            text="This symbol represents services that are available in the Premium
              Package—which includes everything that is avaialble in the
              Essentials Package."
          />
          <Bullet
            alt="check mark"
            src={check}
            text="This symbol represents services that are available in the Essentials Package—which are also available in the Premium Package."
          />
        </div>
        <Included
          title="Accounting Maintenance"
          bullets={[
            "Weekly Transaction Entry / Bookkeeping",
            "!Class Tracking>",
            "!Job / Customer Tracking>",
            "!Detailed Payroll Journal Entries>",
            "Monthly Account Reconciliations",
            "!Limited A/R, A/P, and Inventory Services",
            "QuickBooks Online Account Maintenance",
            "Sales and Use Tax Reporting",
          ]}
        />
        <Included
          title="Monthly Comprehensive Financial Reports"
          bullets={[
            "Balance Sheet",
            "Profit & Loss",
            "Bank Reconciliation Details",
            "Amortization Schedule",
            "Depreciation Schedule",
            "Distribution Schedule",
          ]}
        />
        <Included
          title="Monthly Forecasting"
          bullets={[
            "!Cash Flow Forecast",
            "!Balance Sheet Forecast",
            "!Profit & Loss Forecast",
          ]}
          premium
        />
        <Included
          title="Monthly Consulting Meeting"
          bullets={[
            "!Identify Prosperous Trends",
            "!Monitor Financial Goals",
            "!Personalized Growth Strategies",
            "!Your Questions Answered",
          ]}
          premium
        />
        <Included
          title="Year-End Financial Report"
          bullets={[
            "Clear Yearly Financial Picture",
            "Comprehensive Financial Overview",
            "Streamlined Tax Filing",
            "Identify Trends Year Over Year",
            "Prepare to Get Financing",
          ]}
        />
        <Included
          title="1099 Preparation"
          bullets={[
            "Ensure Accuracy and Compliance",
            "Stress-Free Contracting",
            "One-Step Filing",
          ]}
        />
        <Included
          title="QuickBooks Online Training"
          bullets={[
            "Invoice / Estimate / Bill / Purchase Order Creation",
            "Direct Receipt Submission",
            "On-Demand Reporting",
          ]}
        />
        <Link className="link" to="/contact">
          Get Started
        </Link>
      </div>
      <div className="section">
        <h2>Check out the many benefits.</h2>
        <div className="benefits-2">
          <Benefit
            title="Save Time"
            src={time}
            alt="clock with a counter-clockwise arrow as a frame and the minute hand and hour hands aligned in the shape of a check."
          />
          <Benefit title="Minimize Mistakes" src={perfect} alt="10/10" />
          {/* <Benefit title="Expert Advice" src={advice} alt="chat bubble" /> */}
          <Benefit
            title="Stress-Free Audits"
            src={audit}
            alt="magnifying glass spying a checkmark"
          />
          <Benefit
            title="Financial Guidance"
            src={guidance}
            alt="a bunch of three arrows stemming from one source."
          />
          <Benefit title="Improved Cash Flow" src={maximize} alt="" />
        </div>
        <Link className="link" to="/contact">
          Get Started
        </Link>
      </div>
      <div className="section background">
        <h2 style={{ color: "#886aff" }}>Just look at those prices.*</h2>
        <div className="pricing white">
          <h4>Premium Package</h4>
          <L2R1
            src={small}
            alt="small building"
            left="Small Business"
            right="$750"
          />
          <L2R1
            src={medium}
            alt="medium-sized building"
            left="Medium Business"
            right="$3,500"
          />
          <L2R1
            src={large}
            alt="large business"
            left="Large Business"
            right="$5,000"
          />
        </div>
        <div className="pricing">
          <h4>Essentials Package</h4>
          <L2R1
            src={small}
            alt="small building"
            left="Small Business"
            right="$250"
          />
          <L2R1
            src={medium}
            alt="medium-sized building"
            left="Medium Business"
            right="$1,725"
          />
          <L2R1
            src={large}
            alt="large business"
            left="Large Business"
            right="$3,500"
          />
        </div>
        <Link className="link" to="/contact">
          Get Started
        </Link>
        <span>*Prices represent a monthly recurring charge.</span>
      </div>
      <div className="section">
        <h2>View related resources.</h2>
        <FinanceTracker background />
      </div>
      <Footer />
    </div>
  );
};

export default Bookkeeping;
