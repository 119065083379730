import React, { useEffect } from "react";
import "./App.css";

import routes, { ScrollToTop } from "./pages/routes";

function App() {
  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);

  return (
    <div className="App">
      <ScrollToTop />
      {routes}
    </div>
  );
}

export default App;
