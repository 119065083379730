import React from "react";

import FinanceTracker from "../components/Resource/FinanceTracker";
import NewBusinessChecklist from "../components/Resource/NewBusinessChecklist";

const Resources = () => {
  return (
    <div className="section background">
      <h2>Check out these resources.</h2>
      <FinanceTracker />
      <NewBusinessChecklist />
    </div>
  );
};

export default Resources;
