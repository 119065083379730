import React from "react";
import Intro from "../sections/Intro";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Resources from "../sections/Resources";

const Contacted = () => {
  return (
    <div className="page">
      <Header />
      <Intro primary="Thanks!" secondary="Someone will be in touch soon." />
      <Resources />
      <Footer background />
    </div>
  );
};

export default Contacted;
