import React from "react";
import css from "./L2R1.module.css";

const L2R1 = ({ alt, left, right, src }) => {
  return (
    <div className={css.main}>
      <img className={css.icon} src={src} alt={alt} />
      <p className={css.left}>{left}</p>
      <span className={css.right}>{right}</span>
    </div>
  );
};

export default L2R1;
