import React from "react";
import css from "./Bullet.module.css";

const Bullet = ({ alt, src, text }) => {
  return (
    <li className={css.main}>
      <img className={css.check} src={src} alt={alt} />
      <p className={css.description}>{text}</p>
    </li>
  );
};

export default Bullet;
