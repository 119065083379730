import React from "react";

import Footer from "../components/Footer/Footer";
import ContactForm from "../sections/Form/Contact";
import Header from "../components/Header/Header";

const Contact = () => {
  return (
    <div className="page">
      <Header />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default Contact;
