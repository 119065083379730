import React from "react";
import Resource from "./Resource";
import DownloadForm from "./DownloadForm";

import registration from "../../images/registration.png";

const NewBusinessChecklist = ({ background }) => {
  return (
    <Resource
      background={background}
      title="New Business Registration Checklist"
      description="Starting a business? This free PDF will walk you through everything you need to do to make sure your business is legally registered with the state and federation, so you can do it yourself and save money."
      bullets={[
        "Everything You Need",
        "Step-by-Step Walk-Through",
        "Estimated Costs Included",
        "Expert Advice for Seamless Setup",
      ]}
      src={registration}
      alt="papers"
    >
      <DownloadForm
        asset={{
          href: "https://storage.googleapis.com/crystalclearconsulting.appspot.com/New%20Business%20Registration%20Checklist.pdf",
          download: "New Business Registration Checklist",
          text: "Download Free PDF",
        }}
      />
    </Resource>
  );
};

export default NewBusinessChecklist;
