import React from "react";
import css from "./UL.module.css";
import Bullet from "../../Bullet/Bullet";
import check from "../../../images/check.png";

const UL = ({ children }) => {
  return (
    <ul className={css.main}>
      {children.map((child, index) => (
        <Bullet
          key={index + Math.random()}
          alt="check"
          src={check}
          text={child}
        />
      ))}
    </ul>
  );
};

export default UL;
