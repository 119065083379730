import React from "react";
import css from "./Checkboxes.module.css";

const Checkboxes = ({ items, selected, title, toggle }) => {
  return (
    <div className={css.main}>
      <p className={css.title}>{title}</p>
      {items.map((item, index) => (
        <button
          type="button"
          tabIndex={0}
          className={css.item}
          key={index}
          onClick={() => toggle(item)}
        >
          <div
            className={
              selected?.includes(item)
                ? `${css.check} ${css.checked}`
                : css.check
            }
          />
          <label>{item}</label>
        </button>
      ))}
    </div>
  );
};

export default Checkboxes;
