import React from "react";
import Column2 from "../../components/Blog/Column2/Column2";
import Header from "../../components/Header/Header";
import WhoWhen from "../../components/Blog/WhoWhen/WhoWhen";
import Paragraph from "../../components/Blog/Paragraph/Paragraph";
import UL from "../../components/Blog/UL/UL";
import Footer from "../../components/Footer/Footer";
import example from "./example.png";
import DownloadForm from "../../components/Resource/DownloadForm";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";

const TrackingFinances = () => {
  return (
    <div className="page">
      <Header />
      <div className="bintro">
        <h1>Why and How to Track Your Small Business' Finances</h1>
        <WhoWhen
          name="Crystal Clear Consulting"
          date="Published May 14, 2024"
          src={logo}
          alt="crystal logo"
        />
      </div>
      <div className="bbody">
        <Paragraph>
          It's official: Business owners don't typically like bookkeeping. Well,
          most of them, anyway. We have conducted a few independent surveys, and
          more than 60% of Utah-based businesses we surveyed view bookkeeping in
          a negative light, using terms such as “tedious,” “stressful,” and
          “intimidating” to describe the task. And although we at Crystal Clear
          Consulting don't share the same opinion, we do get it—bookkeeping
          certainly isn't for everyone! Especially without the proper knowledge
          and experience, it definitely can be tedious, stressful, and
          intimidating, to say the least.
        </Paragraph>
        <h2>Why Bother Tracking Your Finances?</h2>
        <Paragraph>
          So, if most business owners find bookkeeping tedious, why does
          everyone do it? That's a great question, and there are actually a lot
          of great reasons. We'll start with a vital and straightforward one:
          It's the law. If you own a business, you are required by law to keep
          track of your financial records, and if you don't, you could face
          severe penalties. Whew! But there are loads of other reasons why
          bookkeeping is a good idea, too (and they're more lighthearted than
          “so you don't get in trouble!”). Here are a few of them:
        </Paragraph>
        <UL>
          {[
            "Monitor the progress of your business",
            "Prepare Financial Statements",
            "Identify Income Sources",
            "Keep Track of Expenses",
            "Prepare Tax Returns",
          ]}
        </UL>
        <Paragraph>
          And the benefits don't end there. But if you still need some extra
          motivation to get your bookkeeping done, don't worry, you're not
          alone—and we're here to help! We offer a library of resources and are
          always happy to answer any finance questions you have. When it comes
          to bookkeeping, you can click right here{" "}
          <Link to="/contact">click here</Link> if you don't want to worry about
          it anymore. But if you're ready to handle your own bookkeeping,
          continue reading to learn more about how to do it.
        </Paragraph>
        <h2>The Information You'll Need to Track for Each Transaction</h2>
        <Paragraph>
          Luckily, the necessary information is intuitive to remember and simple
          to track. For every transaction, regardless of whether it's an income
          or expense, you'll want to note the following information:
        </Paragraph>
        <Column2
          left={
            <img
              style={{ width: "100%", maxWidth: "32rem" }}
              src={example}
              alt="example"
              className={CSS.img}
            />
          }
          right={
            <UL>
              {["The date", "The category", "The payor/payee", "The amount"]}
            </UL>
          }
        />
        <Paragraph>
          It's as simple as that! And because tracking that information is so
          important, virtual transactions track it automatically, including the
          amount, date, and vendor. That leaves you with only one job: to
          categorize the expense appropriately. Piece of cake!
        </Paragraph>
        <Paragraph type="info">
          A word to the wise: Consistency is key! Anyone who has ever
          procrastinated their bookkeeping will attest that it makes it more
          difficult, stressful, and prone to errors. So if you're going to keep
          your own books, try to make it a consistent habit, setting some time
          aside at least once each week to make sure your books are organized
          and accurate.
        </Paragraph>
        <Paragraph>
          One last thing: There are certain situations where your business may
          be required to track additional information in order to remain
          compliant with industry standards. Before you solidify your
          bookkeeping habits, we encourage you to speak with an accountant to
          ensure that you are tracking everything you need to.
        </Paragraph>
        <h2>How to Track Your Finances: 3 Great Options</h2>
        <Paragraph>
          Believe it or not, bookkeeping used to be even more tedious than it is
          today. Bookkeepers would diligently care for physical journals, adding
          every transaction by hand. Nothing was automated, and receipts had to
          be physically stored. Thankfully, those days are long behind us, and
          instead, we have the luxury of choosing between dozens of potential
          options. Here are a few of the options we like to recommend.
        </Paragraph>
        <h3>Option 1: Our FREE Finance Tracker</h3>
        <Paragraph>
          We work with small businesses in every stage of development. Some of
          them are one-person operations who prefer to do their own bookkeeping,
          prioritizing cost-effectiveness over anything else. Our team of
          accountants developed this finance tracker specifically for businesses
          in that stage of development, and we offer it for free for everyone!
          It includes all of the essential features, walking you through exactly
          what to track and how. If you prefer to do your own bookkeeping for
          any reason, we encourage you to give it a try!
        </Paragraph>
        <DownloadForm
          asset={{
            href: "https://storage.googleapis.com/crystalclearconsulting.appspot.com/Financial%20Tracker.xlsx",
            download: "Income and Expense Tracker",
            text: "Download Free for Excel",
          }}
          sheets
        />
        <h3>Option 2: Accounting Software</h3>
        <Paragraph>
          There are dozens of accounting software out there (and there are even
          dozens of lists of accounting software out there). They each have
          their own unique approaches to bookkeeping, but they typically contain
          similar features in general. Regardless of the software you choose, it
          should definitely handle the following things for you:
        </Paragraph>
        <UL>
          {[
            <>
              <strong>Tax integration.</strong> Your software should generate
              any and all reports that your tax accountant will require in order
              to file your taxes. Balance sheets, profit and loss statements,
              and cash flow statements are common ones, but it should be able to
              generate custom reports as well, depending on your needs.
            </>,
            <>
              <strong>Business insights.</strong> Most accounting software are
              advanced enough to where they are able to unlock hidden financial
              insights that were previously only discoverable with the help of
              an accountant.
            </>,
            <>
              <strong>Customizable invoices and receipts.</strong> You should be
              able to send invoices and receipts to your clients straight from
              the software. Not only that, they should be customizable—and you
              should customize them—to match your brand's style. Every
              experience matters. Make them count.
            </>,
            <>
              <strong>Multi-user access.</strong> The best accounting software
              scale with you, allowing you to add various users (such as
              bookkeepers and accountants) to your plan so that you can delegate
              the heavy lifting while still maintaining access to all of your
              information.
            </>,
            <>
              <strong>Good customer service.</strong> Trust us on this. No
              software is perfect. When unexpected things happen, you'll be
              grateful that you chose a provider with good customer service who
              can get you back on track.
            </>,
            <>
              <strong>User-friendliness.</strong> Accounting is a complicated
              process. Thus, accounting software can be complicated, too. And
              that's a good thing—it means it has more features to benefit you
              and your team—but it can be overwhelming. Try a free trial or two
              before committing to make sure the software you choose is
              intuitive.
            </>,
          ]}
        </UL>
        <Paragraph>
          If you prefer to simply trust the experts, we—like many accounting
          professionals—recommend{" "}
          <a
            href="https://quickbooks.intuit.com/oa/get-quickbooks/"
            target="_blank"
            rel="noopener noreferrer"
          >
            QuickBooks Online
          </a>
          . We have direct experience with several accounting software, and we
          have found QuickBooks to be second to none. We can attest to its
          performance, ease of use, and affordability.
        </Paragraph>
        <h3>Option 3: Let Us Handle It</h3>
        <Paragraph>
          This will definitely be the easiest option for you. We'll take
          everything off of your plate, freeing you up to focus on your passion
          while we focus on ours. And the benefits don't end there! Here are a
          few of the other benefits you unlock by choosing to work with us:
        </Paragraph>
        <UL>
          {[
            "Accounting Maintenance",
            "Comprehensive Financial Reports",
            "Forecasting",
            "Financial Consulting",
            "Year-End Financial Reports",
            "1099 Preparation",
            "QuickBooks Online Training (Optional)",
            <Link to="/bookkeeping">Click Here for a Comprehensive List</Link>,
          ]}
        </UL>
        <Paragraph>
          Our mission is to help business owners see their finances more clearly
          and manage them more effectively. That's why we chose to offer
          financial consulting services in addition to bookkeeping. When you
          work with us, we work with you. We want to see you win, we're always
          happy to help with any of your financial needs, and we're only a text,
          call, or email away!
        </Paragraph>
        <Link to="/contact" className="link">
          Reach Out
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default TrackingFinances;
