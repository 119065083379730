import React from "react";
import Intro from "../sections/Intro";
import Benefits from "../sections/Benefits";
import OffYourPlate from "../sections/OffYourPlate";
import Resources from "../sections/Resources";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

const Home = () => {
  return (
    <div className="page">
      <Header />
      <Intro
        primary="Watch us do the heavy lifting."
        secondary="You focus on growing your business and doing more of what you love. We'll handle the stuff like..."
      />
      <Benefits />
      <OffYourPlate />
      <Resources />
      <Footer background />
    </div>
  );
};

export default Home;
