import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Benefit from "../components/Benefit-1/Benefit";

import guidance from "../images/guidance.png";
import maximize from "../images/maximize.png";
import time from "../images/time.png";

const Benefits = () => {
  useEffect(() => {
    const slideFromLeft = (entries) => {
      entries?.forEach((entry) => {
        entry.target.classList.toggle("benefit_show", entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(slideFromLeft, {
      rootMargin: "-180px",
    });

    const benefits = document.getElementsByClassName("benefit");

    Array.from(benefits).forEach((benefit) => {
      observer.observe(benefit);
    });
  }, []);

  return (
    <div className="section background">
      <h2>See how you benefit:</h2>
      <div className="benefits">
        <Benefit
          src={maximize}
          alt="bar chart with increasingly tall bars moving from left to right, as well as an arrow pointing upward and to the right."
          title="Maximize Profits and Savings"
          description="Identify more ways to cut costs, improve profit margins, and claim every eligible tax deduction."
        />
        <Benefit
          src={guidance}
          alt="a bunch of 3 arrows sprouting from a single source."
          title="Get Strategic Financial Guidance"
          description="Our team of accountants and financial advisors want to see you win, and we'll do our best to help."
        />
        <Benefit
          src={time}
          alt="a circular clock with the hour hand and minute hand in the shape of a checkmark and the frame has been replaced by a counter-clockwise-rotating arrow"
          title="Save Stress and Time"
          description="Delegate day-to-day bookkeeping tasks, giving yourself more time to do what you love."
        />
      </div>
      <Link className="link" to="/contact">
        Get Started
      </Link>
    </div>
  );
};

export default Benefits;
