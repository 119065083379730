import React, { useState } from "react";
import css from "./Header.module.css";
import { Link } from "react-router-dom";

import logo from "../../images/logo.png";
import caret from "../../images/caret.png";

const Header = () => {
  const [showNav, setShowNav] = useState(false);
  const [showServices, setShowServices] = useState(false);

  const toggleNav = () => {
    setShowNav((old) => !old);
  };

  const toggleServices = () => {
    setShowServices((old) => !old);
  };

  return (
    <nav className={css.main}>
      <div style={{ display: "flex" }}>
        <div
          className={`${css.hamburger} ${showNav && css.hamburgerOpen}`}
          onClick={toggleNav}
        >
          <div />
          <div />
          <div />
        </div>

        {showNav && (
          <nav className={css.small_nav}>
            <Link onClick={toggleNav} to="/blog">
              Blog
            </Link>
            <Link onClick={toggleNav} to="/resources">
              Resources
            </Link>
            <Link onClick={toggleNav} to="/bookkeeping">
              Bookkeeping
            </Link>
            <Link onClick={toggleNav} to="/consulting">
              Financial Consulting
            </Link>
            <Link onClick={toggleNav} to="/registration">
              Business Registration
            </Link>
            <Link onClick={toggleNav} to="/payroll">
              Payroll Management
            </Link>
            <Link className={css.cta} to="/contact">
              Contact
            </Link>
          </nav>
        )}

        <Link to="/" className={css.logo}>
          <img src={logo} alt="logo" /> <span>Crystal Clear Consulting</span>
        </Link>
      </div>
      <div className={css.links}>
        <Link to="/blog">Blog</Link>
        <Link to="/resources">Resources</Link>
        <div
          className={css.container}
          onMouseEnter={toggleServices}
          onMouseLeave={toggleServices}
          onClick={() => setShowServices(true)}
        >
          <div>
            Services
            <img src={caret} alt="" className={css.caret} />
          </div>
          {showServices && (
            <div className={css.services}>
              <Link to="/bookkeeping">Bookkeeping</Link>
              <Link to="/consulting">Consulting</Link>
              <Link to="/payroll">Payroll</Link>
              <Link to="/registration">Registration</Link>
            </div>
          )}
        </div>
        <Link className={css.cta} to="/contact">
          Contact
        </Link>
      </div>
      <Link className={`${css.cta} ${css.ctaSmall}`} to="/contact">
        Contact
      </Link>
    </nav>
  );
};

export default Header;
