import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import FinanceTracker from "../components/Resource/FinanceTracker";
import Intro from "../sections/Intro";
import NewBusinessChecklist from "../components/Resource/NewBusinessChecklist";

const Resources = () => {
  return (
    <div className="page">
      <Header />
      <Intro
        primary="Check out these resources."
        secondary="Our mission is to help small businesses. In addition to the services we provide, we offer several FREE resources that you can use to manage your finances."
      />
      <div className="section background">
        <NewBusinessChecklist />
        <FinanceTracker />
      </div>
      <Footer background />
    </div>
  );
};

export default Resources;
