import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Input from "../../components/Input/Input";
import Checkboxes from "../../components/Checkboxes/Checkboxes";

const ContactForm = () => {
  const scrollRef = useRef();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [services, setServices] = useState([]);

  const [nameError, setNameError] = useState("");
  const [businessNameError, setBusinessNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [servicesError, setServicesError] = useState([]);

  const [loading, setLoading] = useState(false);

  const updateName = (e) => {
    setNameError("");
    setName(e.target.value);
  };

  const updateBusinessName = (e) => {
    setBusinessNameError("");
    setBusinessName(e.target.value);
  };

  const updateEmail = (e) => {
    setEmailError("");
    setEmail(e.target.value);
  };

  const updatePhone = (e) => {
    setPhoneError("");
    setPhone(e.target.value);
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let body = {
      name,
      businessName,
      email,
      phone,
      services,
      timestamp: new Date(),
    };

    try {
      validate(body);
      let response = await fetch(
        "https://backend-dot-mantle-xm.wm.r.appspot.com/api/crystal-clear-consulting/contact",
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        navigate("/contacted", { replace: true });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  function validate(body) {
    const { name, businessName, email, phone, services } = body;

    let valid = true;

    if (!name || typeof name !== "string") {
      setNameError("Enter your name.");
      valid = false;
    }

    if (!businessName || typeof businessName !== "string") {
      setBusinessNameError("Enter your business' name.");
      valid = false;
    }

    if (!email || typeof email !== "string") {
      setEmailError("Enter your email.");
      valid = false;
    }

    if (!phone || typeof phone !== "string") {
      setPhoneError("Enter your phone number.");
      valid = false;
    }

    if (!services || typeof services !== "object" || services.length === 0) {
      setServicesError("Select at least one service.");
      valid = false;
    }

    if (!valid) {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      throw new Error("Invalid inputs. Please fix the errors with the form.");
    }
  }

  const toggleService = (chosen) => {
    setServicesError("");

    setServices((oldServices) => {
      if (services.includes(chosen)) {
        return oldServices.filter((service) => service !== chosen);
      } else {
        return [...oldServices, chosen];
      }
    });
  };

  return (
    <div className="section" style={{ marginTop: "4rem" }}>
      <h1 ref={scrollRef}>Let's get in touch.</h1>
      <form className="form_contact" onSubmit={submit}>
        <Input
          autoFocus
          error={nameError}
          id="name"
          label="Enter your name."
          onChange={updateName}
          value={name}
        />
        <Input
          error={emailError}
          id="email"
          label="Enter your email."
          onChange={updateEmail}
          type="email"
          value={email}
        />
        <Input
          error={phoneError}
          id="tel"
          label="Enter your phone number."
          onChange={updatePhone}
          type="tel"
          value={phone}
        />
        <Input
          error={businessNameError}
          id="business-name"
          label="Enter your business' name."
          onChange={updateBusinessName}
          value={businessName}
        />
        <Checkboxes
          title={servicesError || "Select the services you're interested in."}
          items={[
            "Financial Consulting",
            "Payroll",
            "Bookkeeping",
            "Business Registration",
          ]}
          toggle={toggleService}
          selected={services}
        />
        <button type="submit">{loading ? "LOADING..." : "SUBMIT"}</button>
      </form>
    </div>
  );
};

export default ContactForm;
