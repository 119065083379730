import React from "react";
import Header from "../components/Header/Header";
import Intro from "../sections/Intro";
import Preview from "../components/Blog/Preview/Preview";

import cover1 from "./Blog/cover1.png";
import cover2 from "./Blog/cover2.png";
import cover3 from "./Blog/cover3.png";
import cover4 from "./Blog/cover4.png";
import cover5 from "./Blog/cover5.png";
import Footer from "../components/Footer/Footer";

const Blog = () => {
  return (
    <div className="page">
      <Header />
      <Intro
        primary="Check out our blog."
        secondary="Our mission is to help small businesses. In addition to the services we provide, we write a new blog post every week to discuss money management and accounting principles."
      />
      <div
        className="section background"
        style={{ flexFlow: "row", flexWrap: "wrap" }}
      >
        <Preview
          title="How Bookkeeping Can Boost Your Hair Salon's Bottom Line"
          link="/blog/hairstylists/how-bookkeeping-can-boost-your-hair-salons-bottom-line"
          description="Owning a hair salon is no easy task. Delegating your bookkeeping can free up time, ease stress, and boost your hair salon's bottom line."
          date="August 14, 2024"
          src={cover5}
          alt="idk"
        />
        <Preview
          title="Hairstylists: Save Time and Stress by Delegating Bookkeeping"
          link="/blog/hairstylists/save-time-and-stress-by-delegating-bookkeeping"
          description="Here's a breakdown of exactly how a bookkeeper can free up a hairstylist's time and energy so they can focus on growing their business."
          date="August 7, 2024"
          src={cover4}
          alt="a pair of salon scissors cutting the word 'stress' in half"
        />
        <Preview
          title="5 Ways an Accountant Can Help Your Small Business Scale"
          link="/blog/5-ways-an-accountant-can-help-your-small-business-scale"
          description="As your small business begins to grow, we can help you scale. Here are 5 specific things we can do to set your business up for success."
          date="June 13, 2024"
          src={cover3}
          alt="five horizontal bars that get darker in color as they go down. each bar has one word written on it. from the top down: 'FUNDING', 'REVENUE', 'TAXES', 'INSIGHTS', 'TIME'"
        />
        <Preview
          title="The Top 3 Tasks to Delegate as Your Small Business Grows"
          link="/blog/the-top-3-tasks-to-delegate-as-your-small-business-grows"
          description="If you're wearing too many hats, it's time to delegate. But which should you offload first? We asked 60 Utah business owners, and the results are in!"
          date="June 01, 2024"
          src={cover2}
          alt="Three side-by-side logos: The Crystal Clear Logo (a purple crystal on its side), the Mantle logo (a stylized 'M' with a circle above it), and the Skallywag Media logo (a cartoonish skull and crossbones)"
        />
        <Preview
          title="Why and How to Track Your Small Business' Finances
					"
          link="/blog/why-and-how-to-track-your-small-business-finances"
          description="Most business owners don't like bookkeeping. So why do they do it? Discover the reasons why bookkeeping is so beneficial, and learn how to go about it in the best way."
          date="May 14, 2024"
          src={cover1}
          alt="The words 'WHY?', 'WHAT?', and 'HOW?' in bold lettering"
        />
      </div>
      <Footer background />
    </div>
  );
};

export default Blog;
