import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Blog from "./Blog";
import Bookkeeping from "./Bookkeeping";
import Consulting from "./Consulting";
import Contact from "./Contact";
import Contacted from "./Contacted";
import Home from "../pages/Home";
import Payroll from "./Payroll";
import Registration from "./Registration";
import Resources from "./Resources";
import Top3TasksToDelegate from "./Blog/Top3TasksToDelegate";
import TrackingFinances from "./Blog/TrackingFinances";
import FiveWaysToScale from "./Blog/FiveWaysToScale";
import Hairstylists from "./Blog/Hairstylists";
import SalonBottomLine from "./Blog/SalonBottomLine";

export const ScrollToTop = () => {
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
};

export default (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/bookkeeping" element={<Bookkeeping />} />
    <Route path="/blog" element={<Blog />} />
    <Route
      path="/blog/why-and-how-to-track-your-small-business-finances"
      element={<TrackingFinances />}
    />
    <Route
      path="/blog/the-top-3-tasks-to-delegate-as-your-small-business-grows"
      element={<Top3TasksToDelegate />}
    />
    <Route
      path="/blog/5-ways-an-accountant-can-help-your-small-business-scale"
      element={<FiveWaysToScale />}
    />
    <Route
      path="/blog/hairstylists/save-time-and-stress-by-delegating-bookkeeping"
      element={<Hairstylists />}
    />
    <Route
      path="/blog/hairstylists/how-bookkeeping-can-boost-your-hair-salons-bottom-line"
      element={<SalonBottomLine />}
    />
    <Route path="/consulting" element={<Consulting />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/contacted" element={<Contacted />} />
    <Route path="/payroll" element={<Payroll />} />
    <Route path="/registration" element={<Registration />} />
    <Route path="/resources" element={<Resources />} />
  </Routes>
);
