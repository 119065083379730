import React from "react";
import Header from "../components/Header/Header";
import Intro from "../sections/Intro";
import Included from "../components/Included/Included";
import Benefit from "../components/Benefit-2/Benefit";
import { Link } from "react-router-dom";
import L2R1 from "../components/L2R1/L2R1";
import Footer from "../components/Footer/Footer";

import perfect from "../images/perfect.png";
import large from "../images/large.png";
import maximize from "../images/maximize.png";
import registration from "../images/registration.png";
import risk from "../images/risk.png";
import time from "../images/time.png";
import NewBusinessChecklist from "../components/Resource/NewBusinessChecklist";

const Registration = () => {
  return (
    <div className="page">
      <Header />
      <Intro
        primary="We'll oversee your business registration."
        secondary="We'll make sure all of your legal ducks are in a row, ensuring a smooth start to your new business."
        image={3}
      />

      <div className="section background">
        <h2>See what's included.</h2>
        <Included
          title="State / Federal Business Registration"
          bullets={[
            "FEIN Registration",
            "State Business Registration / Articles of Organization Filing",
            "Assistance with City Business Registration",
          ]}
        />
        <Included
          title="Tax Account Registration"
          bullets={[
            "State Unemployment Account Registration",
            "State Withholding Account Registration",
            "Sales and Use Tax Registration (If Applicable)",
            "Utah TAP Account Setup",
          ]}
        />
        <Included
          title="Accounting Software Setup"
          bullets={[
            "QuickBooks Online Account Setup",
            "QuickBooks Online Payments Setup (Optional)",
            "Linking Bank Feeds to QuickBooks Online",
          ]}
        />
      </div>

      <div className="section">
        <h2>Check out the many benefits.</h2>
        <div className="benefits-2">
          <Benefit
            title="Save Time"
            src={time}
            alt="clock with backwards arrow"
          />
          <Benefit title="Ensure Compliance" src={perfect} alt="10/10" />
          <Benefit
            title="Best Structure"
            src={large}
            alt="large business buildling"
          />
          <Benefit title="Minimize Liability" src={risk} alt="warning sign" />
          <Benefit
            title="Smooth Start-Up"
            src={maximize}
            alt="a graph showing an upward trend"
          />
        </div>
        <Link className="link" to="/contact">
          Get Started
        </Link>
      </div>

      <div className="section background">
        <h2 style={{ color: "#886aff" }}>Just look at those prices.</h2>
        <div className="pricing">
          <L2R1
            left="New Business Package"
            right="$250"
            alt="business registration"
            src={registration}
          />
        </div>
        <Link className="link" to="/contact">
          Get Started
        </Link>
      </div>

      <div className="section">
        <h2>View related resources.</h2>
        <NewBusinessChecklist background />
      </div>

      <Footer />
    </div>
  );
};

export default Registration;
