import React from "react";

import Service from "../components/Service/Service";

import eggplant from "../images/eggplant.png";
import beet from "../images/beet.png";
import bookkeeping from "../images/bookkeeping.png";
import broccoli from "../images/broccoli.png";
import consulting from "../images/consulting.png";
import corn from "../images/corn.png";
import payroll from "../images/payroll.png";
import registration from "../images/registration.png";

const OffYourPlate = () => {
  return (
    <div className="section">
      <img
        src={broccoli}
        alt="an illustrated broccoli"
        className="food"
        style={{
          bottom: 0,
          left: 0,
        }}
      />
      <img
        src={beet}
        alt="an illustrated beet"
        className="food"
        style={{
          bottom: "48%",
          left: 0,
        }}
      />
      <img
        src={eggplant}
        alt="an illustrated eggplant"
        className="food"
        style={{
          bottom: "24%",
          right: 0,
        }}
      />
      <img
        src={corn}
        alt="an illustrated corn"
        className="food"
        style={{
          bottom: "64%",
          right: 0,
        }}
      />
      <h2>We'll take these off your plate...</h2>
      <div className="services">
        <Service
          alt="pic"
          description="We'll keep your finances accurate and organized so you can spend your time and energy growing your business."
          link="bookkeeping"
          src={bookkeeping}
          title="Bookkeeping"
        />
        <Service
          alt="pic"
          description="We'll help you understand the ins and outs of your finances so that you can make the most informed business decisions."
          link="consulting"
          src={consulting}
          title="Financial Consulting"
        />
        <Service
          alt="two cartoon knapsack bags that are tied at the top with dollar signs drawn on them."
          description="We'll make payday a great day for everybody by handling everything for you, from setup to processing and beyond."
          link="payroll"
          src={payroll}
          title="Payroll Management"
        />
        <Service
          alt="a cartoon paper on top of another paper. the top paper looks like a form."
          description="We'll register your business with the state and federeation, making sure all of your legal ducks are in a row."
          link="registration"
          src={registration}
          title="Business Registration"
        />
      </div>
      <h3 style={{ textAlign: "center" }}>
        ...leaving you with only the good stuff.
      </h3>
    </div>
  );
};

export default OffYourPlate;
