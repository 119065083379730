import React from "react";
import css from "./Footer.module.css";
import { Link } from "react-router-dom";
import logo from "./logo.png";

import crystals from "../../images/crystals.png";

const Footer = ({ background }) => {
  return (
    <div className={css.container}>
      <img
        className={css.crystals}
        src={crystals}
        alt="crystals"
        style={{ background: background ? "#f0ecff" : null }}
      />
      <div className={css.main}>
        <Link to="/" style={{ padding: 0 }}>
          <img
            className={css.logo}
            src={logo}
            alt="crystal clear consulting logo"
          />
        </Link>
        <nav className={css.links}>
          <Link className={css.link} to="/bookkeeping">
            Bookkeeping
          </Link>
          <Link className={css.link} to="/consulting">
            Consulting
          </Link>
          <Link className={css.link} to="/payroll">
            Payroll
          </Link>
          <Link className={css.link} to="/registration">
            Registration
          </Link>
          <Link className={css.link} to="/contact">
            Contact
          </Link>
        </nav>
        <p className={css.copyright}>© 2024 Crystal Clear Consulting</p>
      </div>
    </div>
  );
};

export default Footer;
