import React from "react";
import Resource from "./Resource";

import registration from "../../images/registration.png";
import DownloadForm from "./DownloadForm";

const FinanceTracker = ({ background }) => {
  return (
    <Resource
      background={background}
      title="Finance Tracker"
      description="Running a small business? This free expense tracker will help you stay on top of your finances year-round, helping you simplify tax season, discover hidden insights, and keep your books clean—all on your own."
      bullets={[
        "Instructions Included",
        "Customizable to Fit Your Needs",
        "Easy Income and Expense Tracking",
        "Auto-Generated P&L Statements",
      ]}
      src={registration}
      alt="Finance tracker"
    >
      <DownloadForm
        asset={{
          href: "https://storage.googleapis.com/crystalclearconsulting.appspot.com/Financial%20Tracker.xlsx",
          download: "Income and Expense Tracker",
					text: "Download Free for Excel"
        }}
        sheets
      />
    </Resource>
  );
};

export default FinanceTracker;
