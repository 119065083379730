import React from "react";
import css from "./Column2.module.css";

const Column2 = ({ left, right }) => {
  return (
    <div className={css.main}>
      <div className={css.left}>{left}</div>
      <div className={css.right}>{right}</div>
    </div>
  );
};

export default Column2;
